import Vue from 'vue'
import VueFinalModal from 'vue-final-modal'
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueFinalModal(), { componentName: 'modal' })

new Vue({
  render: h => h(App),
}).$mount('#app')
