<template>
  <div>
    <div
      v-if="site && site.is_archived"
      style="text-align: center; margin-top: 30px; font-size: 22px;"
    >
      This site is archived.
    </div>
    <app
      v-if="!needLogin && !loading && site && site._id"
      :key="key"
      @set-refresh="setRefresh"
      @needAuth="needAuth = true"
    />
    <login-wrap
      :site="site"
      v-if="needLogin && !loading"
    />
    <loading-overlay :showLoadingOverlay="!!showLoadingOverlay" />
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import initComponent from "./utils/external-component";
import loadExtFiles from "./utils/load-ext-files";
import LoadingOverlay from "./components/LoadingOverlay.vue";
import LoginWrap from './components/LoginWrap.vue';

export default {
  name: "RootApp",
  components: {
    LoadingOverlay,
    LoginWrap,
  },
  data() {
    return {
      loading: true,
      showLoadingOverlay: 0,
      key: 0,
      ajaxRefresh: 0,
      fullRefresh: 0,
      needLogin: true,
      siteUri: "",
      site: null,
    };
  },
  mounted() {
    this.loadData();
    this.$root.$on("setLoading", (v) => {
      this.setLoading(v);
    });
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.showLoadingOverlay += 1;
      const apiHost = process.env.VUE_APP_API_HOST;
      const siteUri = window.location.pathname.slice(1).split("/")[0];
      const queryParams = new URLSearchParams(window.location.search);
      const version = queryParams.get("v");
      this.siteUri = siteUri;
      const siteGetUrl = version
        ? `${apiHost}/sites/by-uri-version/${siteUri}/${version}`
        : `${apiHost}/sites/by-uri/${siteUri}`;
      const site = await fetch(siteGetUrl).then((res) => res.json());
      this.site = site;

      if (this.site?.settings?.siteTitle) {
        document.title = this.site.settings.siteTitle;
      }

      if (this.site?.version) {
        const metaTag = document.createElement('meta');
        metaTag.name = "Site Version";
        metaTag.content = this.site.version;

        document.head.appendChild(metaTag);
      }


      if (!this.site._id) {
        this.showLoadingOverlay -= 1;
        return;
      }

      window.localStorage.setItem(
        "login_required",
        !!site.settings.loginRequired
      );

      if (site.settings && site.settings.globalCss) {
        const styleElem = document.createElement("style");
        styleElem.textContent = site.settings.globalCss;
        document.head.append(styleElem);
      }
      if (site.settings && site.settings.globalJs) {
        const scriptElem = document.createElement("script");
        scriptElem.setAttribute("type", "text/javascript");
        scriptElem.textContent = site.settings.globalJs;
        document.body.append(scriptElem);
      }
      if (site.settings && site.settings.globalVariables) {
        site.settings.globalVariables.forEach((variable) => {
          window[variable.name] = variable.value;
        });
      }
      await new Promise((res) => setTimeout(res, 500));

      await loadExtFiles(site.ext_files || []);
      await initComponent(site.components);
      this.setRefresh({
        ajaxRefresh: parseInt(site.settings && site.settings.ajaxReload),
        fullRefresh: parseInt(site.settings && site.settings.fullReload),
      });
      const token = window.localStorage.getItem("site-token");
      if (token) {
        try {
          window.user = jwt_decode(token);
        } catch (err) {
          // do nonthing
        }
      }
      this.needLogin =
        !token &&
        (window.location.pathname === `/${siteUri}/login` ||
          site.settings.loginRequired);

      if (token && window.location.pathname === `/${siteUri}/login`) {
        window.location.reload();
      }

      this.loading = false;
      this.showLoadingOverlay -= 1;
    },
    setRefresh({ ajaxRefresh, fullRefresh }) {
      console.log("ajaxRefresh, fullRefresh: ", ajaxRefresh, fullRefresh);
      if (ajaxRefresh) {
        this.ajaxRefresh = ajaxRefresh;
        if (this.ajaxRefreshHandle) {
          clearInterval(this.ajaxRefreshHandle);
        }
        this.ajaxRefreshHandle = setInterval(() => {
          // this.key = this.key + 1
          this.$root.$emit("ajax-reload");
        }, this.ajaxRefresh * 1000);
      }
      if (fullRefresh) {
        this.fullRefresh = fullRefresh;
        if (this.fullRefreshHandle) {
          clearInterval(this.fullRefreshHandle);
        }
        this.fullRefreshHandle = setInterval(() => {
          window.location.reload();
        }, this.fullRefresh * 1000);
      }
    },
    setLoading(v) {
      this.showLoadingOverlay += v ? 1 : -1;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
