var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showLoadingOverlay)?_c('div',{style:({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,0.3)',
  zIndex: 10000,
})},[_c('span',[_vm._v("Loading...")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }