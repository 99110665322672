<template>
  <div class="html-component" v-html="htmlData" />
</template>
<script>
export default {
  name: 'DynamicHtml',
  props: {
    url: {
      type: String,
      required: true
    },
    js: {
      type: String,
      default: () => ''
    },
    css: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      htmlData: null
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      const token = window.localStorage.getItem('site-token')
      const res = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.text())
      try {
        this.htmlData = JSON.parse(res)
      } catch(err) {
        this.htmlData = res
      }

      if (this.js) {
        if (document.head.querySelector(`script[src="${this.js}"]`)) {
          return
        }
        var scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.src = this.js
        document.head.appendChild(scriptTag)
      }
      if(this.css) {
        if (document.head.querySelector(`link[href="${this.css}"]`)) {
          return
        }
        var link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = this.css
        document.head.appendChild(link)
      }
    }
  }
}
</script>
