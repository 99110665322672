<template>
  <div v-if="showLoadingOverlay" :style="{
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 10000,
  }">
    <span>Loading...</span>
  </div>
</template>
<script>
export default {
  name: 'LoadingOverlay',
  props: {
    showLoadingOverlay: () => false
  }
}
</script>
