<template>
  <login @submit="handleLogin" />
</template>
<script>
import jwt_decode from "jwt-decode";

export default {
  name: "LoginWrapper",
  props: {
    site: null
  },
  methods: {
    async handleLogin(formData) {
      let authUrl = this.site.settings.authUrl;
      const method = this.site.settings.authAPIMethod;

      Object.keys(formData).forEach((key) => {
        authUrl = authUrl.replace(`:${key}`, formData[key]);
      });
      const fetchParam = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (method) {
        fetchParam["method"] = method;
      }
      if (method === "POST") {
        fetchParam["body"] = JSON.stringify(formData);
      }

      const resp = await fetch(authUrl, fetchParam).then((res) => res.text());
      const token = resp;
      window.user = jwt_decode(token);
      window.localStorage.setItem("site-token", token);
      window.location.reload();
    },
  },
};
</script>
