let loaded = {}

const loadTags = async (files) => {
  for (let idx in files) {
    const apiHost = process.env.VUE_APP_API_HOST;
    const file = files[idx]
    if (file.url.startsWith('file')) {
      file.url = file.url.replace('file:', `${apiHost}/s3/`)
    }
    await new Promise(res => {
      switch (file.type) {
        case 'style':
          var link = document.createElement('link')
          link.rel = 'stylesheet'
          link.href = file.url
          document.head.appendChild(link)
          loaded[file.url] = true
          res()
          break
        case 'script':
          var scriptTag = document.createElement('script')
          scriptTag.onload = res
          scriptTag.type = 'text/javascript'
          scriptTag.src = file.url
          document.head.appendChild(scriptTag)
          loaded[file.url] = true
          break
        default:
          break
      }
    })
  }
}

const loadExtFiles = async (files) => {
  await loadTags(files.sort((f1, f2) => {
    return +f1.order - f2.order
  }))
}

export default loadExtFiles
